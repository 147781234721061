import moment from "moment";
import React, { useEffect, useState } from "react";
import Filter from "./Filter";
import { fetchCountries } from "services/lookups";
import Pagination from "components/shared/Pagination";
import TableContent from "./TableContent";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { listWaitingVisaRequests } from "services/visa";
import Locale from "translations";
import { useMemo } from "react";

export default function VisaRequest() {
	const { requests } = Locale;
	const { status } = useParams();
	const [waitingList, setWaitingList] = useState([]);
	const [countries, setCountries] = useState([]);
	const locale = localStorage.getItem("currentLocale") || "en";
	const [meta, setMeta] = useState();
	const [filter, setFilter] = useState({
		id: "",
		destination_id: null,
		visa_type_id: null,
		request_date: null,
		page: null,
	});
	const [visaType, setvisaType] = useState([]);

	const visaTypeOption = [
		{
			id: 1,
			name: { ar: "عمرة", en: "Umra" },
		},
		{
			id: 2,
			name: { ar: "عمل", en: "Work" },
		},
		{
			id: 3,
			name: { ar: "عودة", en: "Return" },
		},
		{
			id: 4,
			name: { ar: "مرور", en: "Umra" },
		},
		{
			id: 5,
			name: { ar: "عمل موسمى", en: "Seasonal work" },
		},
		{
			id: 6,
			name: { ar: "سعودى", en: "Saudi" },
		},
		{
			id: 7,
			name: { ar: "وثيقة", en: "Policy" },
		},
		{
			id: 8,
			name: { ar: "مبعدين", en: "Banished" },
		},
		{
			id: 9,
			name: { ar: "مرحلين", en: "Deportation" },
		},
		{
			id: 10,
			name: { ar: "مجلس تعاون", en: "Cooperation Council" },
		},
		{
			id: 11,
			name: { ar: "أصدار الخارج", en: "Overseas version" },
		},
		{
			id: 12,
			name: { ar: "اقامة", en: "Accommodation" },
		},
		{
			id: 13,
			name: { ar: "حج", en: "Hajj" },
		},
		{
			id: 14,
			name: { ar: "عودة أجانب", en: "Return of foreigners" },
		},
		{
			id: 15,
			name: { ar: "مرور أجانب", en: "Passage of foreigners" },
		},
		{
			id: 16,
			name: { ar: "زيارة أجانب", en: "Visit foreigners" },
		},
		{
			id: 17,
			name: { ar: "خاص", en: "Private" },
		},
		{
			id: 18,
			name: { ar: "دبلوماسي", en: "Diplomat" },
		},
		{
			id: 19,
			name: { ar: "خروج نهائي", en: "Final Exit" },
		},
	];
	useEffect(() => {
		async function fetchLookups() {
			const res = await fetchCountries();

			const format = res?.data.map((t) => ({
				value: t.id,
				label: t.names[locale],
			}));
			setCountries(format);

			const formatVisa = visaTypeOption.map((t) => ({
				value: t.id,
				label: t.name[locale],
			}));
			setvisaType(formatVisa);
		}
		fetchLookups();
	}, []);
	useEffect(() => {
		setWaitingList([]);
	}, [status]);

	useEffect(() => {
		const fetchWaitingVisaList = async () => {
			const response = await listWaitingVisaRequests({
				...filter,
				destination_id: filter.destination_id?.value,
				visa_type_id: filter.visa_type_id?.value,
				request_date: filter.request_date
					? moment(filter.request_date).format("YYYY-MM-DD")
					: "",
				status,
			});

			if (response.status === 200 || response.status === 201) {
				setWaitingList(response.data.data);
				setMeta(response.data.meta);
			}
		};

		if (status) {
			fetchWaitingVisaList();
		}
	}, [status, filter]);

	const listRequests =
		waitingList.length > 0 ? (
			waitingList?.map((item, index) => {
				return <TableContent data={item} key={index} />;
			})
		) : (
			<tr>
				<td colSpan="10" className="text-center my-3">
					<div className="product-build__product-no-data">
						<i className="fas fa-info-circle fa-lg"></i> <h4>No Result</h4>
					</div>
				</td>
			</tr>
		);

	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	return (
		<div>
			<div className="row m-0 p-0 ">
				<div className="col-12 d-flex flex-column align-items-end  justify-content-start">
					<Filter
						filter={filter}
						setFilter={setFilter}
						countries={countries}
						visaType={visaType}
					/>
				</div>
				<div className="w-100">
					<div class="card-body p-0">
						<div class="table-responsive">
							<table className="table mb-0 f-15">
								<thead className="table-light">
									<tr className="">
										<th>ID</th>
										<th>{requests.Destination}</th>
										<th>{requests.VisaType}</th>
										<th>
											<i class="fas fa-sort"></i> {requests.Pax}
										</th>
										<th>
											<i class="fas fa-sort"></i> {requests.RequestDate}
										</th>
										<th>{requests.Status}</th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>{listRequests}</tbody>
							</table>{" "}
						</div>
					</div>
				</div>
				<div className="col-12"> </div>
				<Pagination info={meta} goTo={goTo} />
			</div>
		</div>
	);
}
